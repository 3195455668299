import React from 'react';
import "./withdraw.css";

function Withdraw() {

  return (
    <div className="deposit-form-container">
    <h2 className="form-title">Withdrawals</h2>
    <div className="bottom">
    <div className='deposit'>
      <p className="date">Date</p>
      <p className="amount">Amount</p>
    </div>
    <div className='deposit'>
      <p className="date">2016/12/15</p>
      <p className="amount">$50,000</p>
    </div>
    <div className='deposit'>
      <p className="date">2016/10/30</p>
      <p className="amount">$2,500</p>
    </div>
    <div className='deposit'>
      <p className="date">2016/08/05</p>
      <p className="amount">$10,000</p>
    </div>
    <div className='deposit'>
      <p className="date">2016/05/10</p>
      <p className="amount">$15,000</p>
    </div>
    <div className='deposit'>
      <p className="date">2016/02/25</p>
      <p className="amount">$10,000</p>
    </div>
    <div className='deposit'>
      <p className="date">2013/11/11</p>
      <p className="amount">$5,000</p>
    </div>
    <div className='deposit'>
      <p className="date">2015/09/15</p>
      <p className="amount">$5,000</p>
    </div>
    <div className='deposit'>
      <p className="date">2015/06/10</p>
      <p className="amount">$2,000</p>
    </div>
    <div className='deposit'>
      <p className="date">2013/11/11</p>
      <p className="amount">$2,500</p>
    </div>
    </div>
  </div>
  )
}

export default Withdraw