import React from 'react';
import "./depositForm.css";

function DepositForm() {
  return (
    <div className="deposit-form-container">
    <h2 className="form-title">Deposits</h2>
    <div className="bottom">
    <div className='deposit'>
      <p className="date">Date</p>
      <p className="amount">Amount</p>
    </div>
    <div className='deposit'>
      <p className="date">2016/12/30</p>
      <p className="amount">$52,000</p>
    </div>
    <div className='deposit'>
      <p className="date">2016/12/01</p>
      <p className="amount">$2,303,000</p>
    </div>
    <div className='deposit'>
      <p className="date">2016/11/30</p>
      <p className="amount">$2,500</p>
    </div>
    <div className='deposit'>
      <p className="date">2016/09/20</p>
      <p className="amount">$25,000</p>
    </div>
    <div className='deposit'>
      <p className="date">2016/06/05</p>
      <p className="amount">$50,000</p>
    </div>
    <div className='deposit'>
      <p className="date">2016/03/03</p>
      <p className="amount">$30,000</p>
    </div>
    <div className='deposit'>
      <p className="date">2015/11/01</p>
      <p className="amount">$50,000</p>
    </div>
    <div className='deposit'>
      <p className="date">2015/08/03</p>
      <p className="amount">$10,000</p>
    </div>
    <div className='deposit'>
      <p className="date">2014/02/10</p>
      <p className="amount">$20,00</p>
    </div>
    <div className='deposit'>
      <p className="date">2013/11/11</p>
      <p className="amount">$50,000 </p>
    </div> 
    </div>
  </div>
  )
}

export default DepositForm